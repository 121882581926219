<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  background-color: #f6f6f6;
}

.bg--global {
  background-color: #f6f6f6;
}
</style>
